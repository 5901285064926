<footer class="site-footer section">
  <div class="container pb-3">
    <div class="row gap-y text-center text-md-left">
      <div class="col-md-4 mr-auto">
        <img src="assets/img/logo.png" alt="" class="logo" />

        <p>
          Reporting Pal works with you to acomplish your goals. We hire and
          provide you with the best resources to make your implementation a
          success story.
        </p>
      </div>

      <div class="col-md-2">
        <h6 class="py-2 bold">Company</h6>

        <nav class="nav flex-column">
          <a class="nav-item py-2" [routerLink]="['/about']">About</a>
          <a class="nav-item py-2" href="" (click)="scroll('header')"
            >Services</a
          >
        </nav>
      </div>

      <div class="col-md-2">
        <h6 class="py-2 bold">Legal</h6>

        <nav class="nav flex-column">
          <a
            class="nav-item py-2"
            href="https://www.websitepolicies.com/policies/view/7enaWXtM"
            target="_blank"
            >Refund Policy</a
          >
          <a
            class="nav-item py-2"
            href="https://www.websitepolicies.com/policies/view/mdRi16tW"
            target="_blank"
            >Privacy Policy</a
          >
          <a
            class="nav-item py-2"
            href="https://www.websitepolicies.com/policies/view/41ulLtrR"
            target="_blank"
            >Terms and Conditions</a
          >
        </nav>
      </div>

      <div class="col-md-2">
        <h6 class="py-2 bold">Channels</h6>

        <nav class="nav flex-column">
          <a class="nav-item py-2" [routerLink]="['/contact']" target="_top"
            >Contact</a
          >
        </nav>
      </div>
    </div>

    <hr class="mt-5" />
    <div class="row small align-items-center">
      <div class="col-md-4">
        <p class="mt-2 mb-md-0 text-secondary text-center text-md-left">
          © {{ currentDate | date: 'yyyy' }} Reporting Pal. All Rights Reserved
        </p>
      </div>

      <div class="col-md-8">
        <nav class="nav justify-content-center justify-content-md-end">
          <a
            href="https://www.facebook.com/reportingpal"
            class="btn btn-circle btn-sm btn-secondary mr-3 op-4"
            target="_blank"
          >
            <fa-icon [icon]="fa.faFacebook"></fa-icon>
          </a>
          <!-- <a href="#" class="btn btn-circle btn-sm btn-secondary mr-3 op-4">
            <fa-icon [icon]="fa.faTwitter"></fa-icon>
          </a>
          <a href="#" class="btn btn-circle btn-sm btn-secondary op-4">
            <fa-icon [icon]="fa.faInstagram"></fa-icon>
          </a> -->
        </nav>
      </div>
    </div>
  </div>
</footer>
