<swiper [config]="config" class="swiper-container pb-5">
  <div class="swiper-slide" *ngFor="let i of [1, 2, 3, 4, 5, 6]">
    <img
      src="assets/img/logos/{{ i }}.png"
      class="img-responsive"
      alt=""
      style="max-height: 60px"
    />
  </div>
</swiper>
