import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dc-developers',
  templateUrl: './developers.component.html',
  styleUrls: ['./developers.component.scss']
})
export class DevelopersComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
