// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const environment = {
  production: true,
  hmr: false,
  version: env.npm_package_version + '-dev',
  serverUrl: '/api',
  defaultLanguage: 'en-US',
  supportedLanguages: ['en-US'],
  rtl: false,
  rpCloud: `http://localhost:5001/reportingpal-site-prod/us-central1/rpcloud`,
  rpCloudFunctionUsername: 'rpscs',
  rpCloudFunctionPassword: '#ssE[_4v(TJT#$892?$[opjjGTTG@59',

  firebaseConfig: {
    apiKey: 'AIzaSyB3Se0qLDGPRyfMDl2jRlYOSeplVHNE0uw',
    authDomain: 'reportingpal-site-prod.firebaseapp.com',
    databaseURL: 'https://reportingpal-site-prod.firebaseio.com',
    projectId: 'reportingpal-site-prod',
    storageBucket: 'reportingpal-site-prod.appspot.com',
    messagingSenderId: '1030490713959',
    appId: '1:1030490713959:web:b2bc7ec2fabfcb373749c2',
    measurementId: 'G-Y1N3ZF9LJL'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
