<header
  class="header page section parallax image-background blanket gradient gradient-purple-navy alpha-8 text-contrast"
  [style.background-image]="'url(assets/img/bg/grid.jpg)'"
  (_navigateHeading)="scroll($event)"
>
  <div #header class="container overflow-hidden">
    <div class="row">
      <div class="col-md-12">
        <h1 class="h1 text-contrast light ">Welcome to Reporting Pal!</h1>
        <h1 class="h2 text-contrast light">Automating your world</h1>

        <h4 class="text-contrast">
          <ngx-typed-js
            [strings]="[
              'Data Analysts',
              'Web Applications',
              'Google Apps Scripts',
              'Process Documentation',
              'Software Quality Assurance',
              'Real Time Analysts',
              'Full Time Developers',
              'Process Enhancements',
              'Mobile friendly sites'
            ]"
            [typeSpeed]="100"
            [backDelay]="500"
            [backSpeed]="50"
            [loop]="true"
          >
            <span class="typing typed bold display-4 display-md-5"></span>
          </ngx-typed-js>
        </h4>
        <nav class="nav mt-5">
          <a
            [routerLink]="['/contact']"
            class="nav-link btn btn btn-rounded btn-contrast btn-lg px-5 bold"
          >
            Get in touch with us!
          </a>
        </nav>
      </div>
    </div>
  </div>

  <!-- <dc-developers-bubbles></dc-developers-bubbles> -->
</header>
