import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RequestTokenService {

  constructor(public http: HttpClient) { }

  async getRefreshToken(check:boolean): Promise<any> {
    const token = localStorage.getItem('api-refresh-token');
    if (token != undefined && check) {
      return token
    }
    const formBody = {
      username: environment.rpCloudFunctionUsername,
      password: environment.rpCloudFunctionPassword
    }

    const tokenRequest:any = await this.http.post(environment.rpCloud + '/auth/get-refresh-token', formBody).toPromise()
    console.log('this is the refresh token', tokenRequest?.data)
    if (tokenRequest?.status === true && tokenRequest?.data) {
      localStorage.setItem('api-refresh-token', tokenRequest?.data)
      return tokenRequest?.data
    }
    return undefined
  }

  async getAndSaveSCSToken(check:boolean){
    const token = localStorage.getItem('api-scs-token');
    if (token != undefined && check) {
      return token
    }
    const refreshToken = await this.getRefreshToken(check)
    const headers = {
      Authorization: `Bearer ${refreshToken}`,
      'Access-Control-Allow-Origin': '*'
    }
    const tokenRequest:any = await this.http
      .post(environment.rpCloud + '/auth-scs/get-token-scs', {}, {headers:headers})
      .toPromise()

    if (tokenRequest?.status === true && tokenRequest?.data) {
        console.log('this is the SCS token', tokenRequest?.data)
        localStorage.setItem('api-scs-token', tokenRequest?.data)
        return tokenRequest?.data
    }
    return null
  }

  async getHeadersWithLocalHostTokens(){
    const tokenSCS = await this.getAndSaveSCSToken(true)
    return {
      Authorization: 'Bearer ' + tokenSCS, 
        'Access-Control-Allow-Origin': '*'
    }
  }

}
