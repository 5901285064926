import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared';
import { CountersModule } from '../../blocks/counters/counters.module';
import { FootersModule } from '../../blocks/footers/footers.module';
import { SlidersModule } from '../../blocks/sliders/sliders.module';
import { UsualModule } from '../../blocks/usual/usual.module';
import { CtaModule } from '../../blocks/cta/cta.module';

import { DevelopersRoutingModule } from './developers-routing.module';
import { DevelopersComponent } from './components/developers/developers.component';
import { DevelopersHeadingComponent } from './components/developers-heading/developers-heading.component';
import { DevelopersIntegrationComponent } from './components/developers-integration/developers-integration.component';
import { DevelopersIntegrationToolsComponent } from './components/developers-integration-tools/developers-integration-tools.component';
import { NgxTypedJsModule } from 'ngx-typed-js';
import { DevelopersBubblesComponent } from './components/developers-bubbles/developers-bubbles.component';
import { BubbleComponent } from './components/bubble/bubble.component';
import { BubblesService } from './services/bubbles.service';

@NgModule({
  providers: [BubblesService],
  declarations: [
    DevelopersComponent,
    DevelopersHeadingComponent,
    DevelopersIntegrationComponent,
    DevelopersIntegrationToolsComponent,
    DevelopersBubblesComponent,
    BubbleComponent
  ],
  imports: [
    SharedModule,
    CountersModule,
    FootersModule,
    SlidersModule,
    UsualModule,
    CtaModule,
    DevelopersRoutingModule,
    NgxTypedJsModule
  ]
})
export class DevelopersModule {}
