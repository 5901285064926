<section class="section">
  <div class="container bring-to-front">
    <div class="shadow rounded text-center bg-info-gradient p-5">
      <h2 class="mb-5 text-contrast">Try Dashcore now.... love it forever</h2>
      <p class="handwritten highlight font-md text-alternate">
        Why wait? Start now!
      </p>

      <a
        [routerLink]="['/auth/register']"
        class="btn btn-darker btn-rounded mt-4"
        >Start now</a
      >
    </div>
  </div>
</section>
