<dc-developers-heading></dc-developers-heading>

<dc-developers-integration></dc-developers-integration>

<!-- TODO: prismjs issue when migrating to Angular 10. The following component couldn't be loaded -->
<!-- <dc-slider-developer-tools></dc-slider-developer-tools> -->

<dc-developers-integration-tools></dc-developers-integration-tools>

<dc-trust-us [title]="'They trust us, would you?'"></dc-trust-us>

<dc-footer1></dc-footer1>
