import { ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'dc-developers-heading',
  templateUrl: './developers-heading.component.html',
  styleUrls: ['./developers-heading.component.scss']
})
export class DevelopersHeadingComponent implements OnInit {
  @ViewChild('header', { static: false }) header;

  constructor() {}

  ngOnInit() {}

  scroll(element: string) {
    this[element].nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
}
