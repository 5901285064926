import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Shell } from '@app/shell/services/shell.service';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'about',
      loadChildren: () =>
        import('./pages/about/about.module').then(m => m.AboutModule)
    },
    {
      path: 'contact',
      loadChildren: () =>
        import('./pages/contact/contact.module').then(m => m.ContactModule)
    },
    // {
    //   path: 'faqs',
    //   loadChildren: () =>
    //     import('./pages/faqs/faqs.module').then(m => m.FaqsModule)
    // },
    {
      path: 'pricing',
      loadChildren: () =>
        import('./pages/pricing/pricing.module').then(m => m.PricingModule)
    },
    {
      path: 'services',
      loadChildren: () =>
        import('./pages/services/services.module').then(m => m.RPServicesModule)
    }
  ]),
  {
    path: 'terms',
    loadChildren: () =>
      import('./pages/terms/terms.module').then(m => m.TermsModule)
  },

  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
