<section class="section built-tech">
  <div class="container">
    <div class="shapes-container">
      <div class="shape shape-circle" data-aos="fade-up">
        <div></div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6 col-lg-7">
        <div class="card shadow border-0">
          <div class="card-body">
            <div class="row">
              <div class="col-md-8 b-md-r">
                <span class="badge badge-primary badge-pill bold px-4 py-2"
                  >Always Innovating</span
                >
                <h2 class="mt-3">
                  <span class="bold">Industry leading</span> technologies
                </h2>
                <p class="text-muted">
                  Reporting Pal has your back. We are always open to new and
                  current things but here's a taste of what we've worked with.
                </p>

                <figure class="">
                  <img
                    src="assets/img/logos/webpack.svg"
                    class="img-responsive mx-auto"
                    alt=""
                  />
                </figure>
              </div>
              <div class="col-md-4 d-flex flex-column justify-content-around">
                <div class="text-center" *ngFor="let stack of stacks">
                  <figure class="mockup">
                    <img
                      [src]="'assets/img/logos/' + stack.image + '.svg'"
                      alt=""
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 col-lg-5">
        <div class="shapes-container">
          <div class="shape shape-ring shape-ring-1" data-aos="fade-up">
            <div></div>
          </div>
        </div>

        <div class="section-heading">
          <figure class="mockup ml-0">
            <img src="assets/img/integration/tech-globe.svg" alt="" />
          </figure>
          <h2 class="my-3 font-md">
            <span class="bold">Using what is necessary</span> to deliver the
            solution that you need
          </h2>

          <p class="text-muted">
            Don't hold back. We will always take an honest approach. If we can't
            do it, we will tell you. Best of all, It takes minutes to ask and it
            is free!
          </p>
        </div>

        <div class="row">
          <div class="col-md-6" *ngFor="let item of items">
            <div class="media pb-3">
              <div
                class="bg-light p-3 rounded d-flex align-items-center justify-content-center mr-3"
              >
                <dc-feather
                  [name]="item.icon"
                  [iconClass]="'stroke-primary'"
                ></dc-feather>
              </div>
              <div class="media-body">
                <h5>{{ item.title }}</h5>
                <p>{{ item.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
