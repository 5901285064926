import { Component, OnInit } from '@angular/core';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'dc-developers-integration-tools',
  templateUrl: './developers-integration-tools.component.html',
  styleUrls: ['./developers-integration-tools.component.scss']
})
export class DevelopersIntegrationToolsComponent implements OnInit {
  stacks = [
    { name: 'Javascript', image: 'javascript' },
    { name: 'Javascript', image: 'es6' },
    { name: 'Styles', image: 'node-sass' }
  ];
  items = [
    {
      icon: 'link',
      title: 'Connecting',
      description: 'Get your current tools working together'
    },
    {
      icon: 'upload-cloud',
      title: 'Cloud based',
      description: "You don't need servers, we will use cloud technology"
    },
    {
      icon: 'cloud-lightning',
      title: 'Fast',
      description: "We won't make you wait to kick-off a project"
    },
    {
      icon: 'coffee',
      title: 'Coffee',
      description: 'All things are driven by something'
    }
  ];

  longAarrowAltRight = faLongArrowAltRight;

  constructor() {}

  ngOnInit() {}
}
