import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ShellModule } from './shell/shell.module';
import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@app/shared';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { DevelopersModule } from './pages/developers/developers.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '@env/environment';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxLocalStorageModule } from 'ngx-localstorage';
import { RequestInterceptorServiceInterceptor } from './request-interceptor-service.interceptor'

@NgModule({
  imports: [
    BrowserModule,
    TranslateModule.forRoot(),
    ShellModule,
    SharedModule,
    NgbDropdownModule,
    DevelopersModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot({ maxOpened: 3 }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AppRoutingModule, // must be imported as the last module as it contains the fallback route
    ReactiveFormsModule,
    NgxLocalStorageModule.forRoot()
  ],
  declarations: [AppComponent],
  providers: [ {
    provide: HTTP_INTERCEPTORS,
    useClass: RequestInterceptorServiceInterceptor,
    multi: true
}],
  bootstrap: [AppComponent]
})
export class AppModule {}
