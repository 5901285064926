<section
  class="section bg-{{ background || 'light' }} {{
    edge ? 'edge ' + edge : ''
  }}"
>
  <div class="container" [ngClass]="containerClass">
    <h4 class="bold text-center mb-5" *ngIf="title">
      {{ title }}
    </h4>

    <div class="row gap-y">

      <div class="col-md-3 col-xs-4 col-6 center-img" *ngFor="let i of [1]">
        <img
          [src]="'assets/img/logos/' + i + '.png'"
          alt=""
          class="img-responsive mx-auto op-7"
          style="max-height: 60px;"
        />
      </div>
      
    </div>
  </div>
</section>
