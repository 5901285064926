import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  faFacebook,
  faTwitter,
  faInstagram
} from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'dc-footer1',
  templateUrl: './footer1.component.html',
  styleUrls: ['./footer1.component.scss']
})
export class Footer1Component implements OnInit {
  @Output()
  private _navigateHeading: EventEmitter<any> = new EventEmitter();

  currentDate: Date = new Date();

  fa = {
    faFacebook: faFacebook,
    faTwitter: faTwitter,
    faInstagram: faInstagram
  };

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {}

  ngOnInit() {}

  scroll(element: string) {
    if (this.activatedRoute.firstChild === null) {
      this._navigateHeading.emit(element);
    } else {
      this.router.navigate(['/']);
    }
  }
}
