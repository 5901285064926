import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpClient,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpSentEvent,
  HttpUserEvent
} from '@angular/common/http';

import { Observable, from, BehaviorSubject, of, defer, concat } from 'rxjs';
import { catchError, map, mergeMap, switchMap,  } from 'rxjs/operators';
import { RequestTokenService } from './request-token.service';
import { environment } from '../environments/environment';

@Injectable()
export class RequestInterceptorServiceInterceptor implements HttpInterceptor {
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(private requestTokenService: RequestTokenService, private http: HttpClient, ) { }

  addToken(req: HttpRequest<unknown>, token: string): HttpRequest<unknown> {
    return req.clone({ setHeaders: { Authorization: 'Bearer ' + token, 'Access-Control-Allow-Origin': '*' }});
  }

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>>  {

    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse, caught:Observable<any>) =>{
        if(err.status === 401 || err.status === 403){
          return concat(
            from(this.requestTokenService.getAndSaveSCSToken(false)),
            this.handleError(err, req, next))
        }
      })
    )
  }

  handleError(err: HttpErrorResponse, req: HttpRequest<any>, next: HttpHandler):Observable<HttpEvent<any>> {
    const newToken = localStorage.getItem('api-scs-token')
    console.log('HANDLE ERROR', newToken)
    return next.handle(this.addToken(req, newToken));
  }

  /*
  async handle(req: HttpRequest<any>, next: HttpHandler) {
    if(req.url.includes('auth')){
      // to avoid a loop
      return next.handle(req)
    }

    const tokenSCS = await this.requestTokenService.getAndSaveSCSToken()
    console.log(tokenSCS)
    const authReq = req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + tokenSCS, 
        'Access-Control-Allow-Origin': '*'
      }
    })

    // Important: Note the .toPromise()
    return next.handle(authReq)
    .pipe(
      catchError((err: HttpErrorResponse) => {
        console.log(err);
        if (err.status === 401) {
          console.log('No authorizado')
        }
        return Observable.throw(err);
      })
    )
    .toPromise()
  }
  */

}
