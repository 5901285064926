import { Component, OnInit } from '@angular/core';
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'dc-developers-integration',
  templateUrl: './developers-integration.component.html',
  styleUrls: ['./developers-integration.component.scss']
})
export class DevelopersIntegrationComponent implements OnInit {
  longArrowAltRight = faLongArrowAltRight;

  elements = [
    {
      icon: 'work',
      title: 'Document your process',
      description:
        'Our Project Managers use Lucidchart to document your needs, suggest changes, and get back to you with a Swimlane workflow diagram.'
    },
    {
      icon: 'plan',
      title: 'Set work time estimates',
      description:
        "We know your time is important. That's why, with the agreed upon Finalized Swimlane we set time estimates within a Jira project."
    },
    {
      icon: 'payment',
      title: 'Provide a quote',
      description:
        'A happy employee is a productive employee. We send a fair and competitive quote based on time estimates. Should you require a Full Time Employee, we bill bi-weekly.'
    }
  ];

  constructor() {}

  ngOnInit() {}
}
